import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  drawer: {
    fontWeight: "bold",
    "& .MuiDrawer-paper": {
      borderRight: "1px solid rgba(255, 255, 255, 0.12)",
      backgroundColor: theme.palette.background.default,
      boxShadow: "3px 0px 10px #00000010",
    },
    "& .MuiButtonBase-root.MuiListItem-root": {
      justifyContent: "space-between",
      borderRadius: "8px",
    },
    "& .MuiTypography-overline": {
      margin: "10px 20px 10px",
    },
    "& p": {
      margin: "unset",
    },
  },
  homeListItem: {
    borderRadius: "8px",
    "& p": {
      color: theme.palette.text.primary,
    },
  },
  itemActive: {
    backgroundColor: theme.palette.blue.darker,
    "& p": {
      color: `${theme.palette.static.white} !important`,
    },
    "&:hover": {
      backgroundColor: theme.palette.blue.darker,
    },
    "& svg": {
      fill: `${theme.palette.static.white} !important`,
    },
  },
  helpListItem: {
    backgroundColor: theme.palette.static.blandWhite,
    borderRadius: "8px",
    "& p": {
      color: theme.palette.static.darkerGrey,
    },
    "&:hover": {
      backgroundColor: theme.palette.static.blandWhite,
    },
    "& svg": {
      marginTop: "6px",
    },
  },
  helpListItemDark: {
    backgroundColor: theme.palette.static.blandGrey,
    "& p": {
      color: theme.palette.static.mutedWhite,
    },
    "&:hover": {
      backgroundColor: theme.palette.static.blandGrey,
    },
  },
  helpOpen: {
    borderBottomLeftRadius: "0",
    borderBottomRightRadius: "0",
  },
  helpSubMenu: {
    backgroundColor: theme.palette.static.blandWhite,
    borderBottomLeftRadius: "8px",
    borderBottomRightRadius: "8px",
    "& a": {
      color: theme.palette.static.darkerGrey,
    },
  },
  helpSubMenuDark: {
    backgroundColor: theme.palette.static.blandGrey,
    "& a": {
      color: theme.palette.static.mutedWhite,
    },
  },
  list: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    "& > div": {
      marginBottom: "30px",
      "& a": {
        width: "100%",
        textDecoration: "none",
      },
    },
    "& > ul.MuiList-root.MuiList-padding": {
      paddingTop: "unset",
      paddingBottom: "unset",
    },
    "& > ul > div:not(:last-child)": {
      marginBottom: "8px",
    },
  },
  listItem: {
    display: "flex",
    alignItems: "center",
    "& > svg": {
      marginRight: "8px",
    },
  },
  menuBtnCollapse: {
    display: "flex",
    paddingRight: "0",
    paddingLeft: "0",
    "&.MuiButtonBase-root.MuiListItem-root": {
      justifyContent: "center",
    },
  },
  listItemCollapse: {
    "& > svg": {
      margin: "0",
    },
  },
  drawerHeader: {
    "& svg": {
      marginRight: "auto",
    },
    "&.drawerHeaderCollapse": {
      justifyContent: "center",
    },
    ...theme.mixins.toolbar,
  },
  myTasks: {
    marginTop: "20px",
    "& .MuiList-root >*": {
      marginBottom: "8px",
    },
  },
  feedbackLink: {
    textDecoration: "none",
    color: "inherit",
    "&:hover": {
      textDecoration: "none",
    },
  },
}));

export default useStyles;
